<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <logo />
          <h2 class="brand-text text-primary ml-1">
            PRS
          </h2>
        </b-link>

        <b-card-title class="mb-1">
           Welcome to Axum's Performance Review System! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Please sign-in to your account
        </b-card-text>

        <!-- <b-alert variant="primary" show>
          <div class="alert-body font-small-2">
            <p>
              <small class="mr-50"><span class="font-weight-bold">Admin:</span> saithssayene@axum.earth |
                admin123!</small>
            </p>
            <p>
              <small class="mr-50"><span class="font-weight-bold">User:</span> haithssayene@axum.earth |
                admin123!</small>
            </p>
          </div>
          <feather-icon v-b-tooltip.hover.left="'This are only Demo accounts.'" icon="HelpCircleIcon" size="18"
            class="position-absolute" style="top: 10; right: 10;" />
        </b-alert> -->

        <!-- form -->
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent="login">

            <!-- email -->
            <b-form-group label-for="email" label="Email">
              <validation-provider #default="{ errors }" name="Email" rules="required|email">
                <b-form-input id="email" v-model="userEmail" name="login-email"
                  :state="errors.length > 0 ? false : null" placeholder="john@axum.earth" autofocus />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <!-- <b-link :to="{ name: 'forgot-password' }">
                  <small>Forgot Password?</small>
                </b-link> -->
              </div>
              <validation-provider #default="{ errors }" name="Password" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="password" v-model="password" :type="passwordFieldType" class="form-control-merge"
                    :state="errors.length > 0 ? false : null" name="login-password" placeholder="Password" />

                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                Remember Me
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button class="mt-3 mb-1" variant="primary" type="submit" block :disabled="invalid">
              Sign in
            </b-button>
          </b-form>
        </validation-observer>

        <!-- <b-card-text class="text-center mt-2">
          <span>New on our platform? </span>
          <b-link :to="{ name: 'register' }">
            <span>Create an account</span>
          </b-link>
        </b-card-text> -->

        <!-- <div class="divider my-0">
          <div class="divider-text">
            or
          </div>
        </div> -->

        <!-- social button -->
        <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button href="javascript:void(0)" variant="facebook">
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button href="javascript:void(0)" variant="twitter">
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button href="javascript:void(0)" variant="google">
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button href="javascript:void(0)" variant="github">
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div> -->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BAlert, BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import Logo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAppConfig from '@core/app-config/useAppConfig'



export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    // BSV
    BAlert,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    Logo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const {
      isNavMenuHidden,
    } = useAppConfig()
    return {
      isNavMenuHidden
    }
  },
  data() {
    return {
      status: '',
      userEmail: '',
      password: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              const userData = response.data
              if (userData.isActive) {
                if (userData.role === "admin") {
                  userData.ability = [
                    {
                      action: 'manage',
                      subject: 'AdminAccess',
                    },
                    {
                      action: 'manage',
                      subject: 'PartnerAccess',
                    },
                    {
                      action: 'manage',
                      subject: 'UserAccess',
                    },
                    {
                      action: 'manage',
                      subject: 'InactiveUserAccess',
                    },
                    {
                      action: 'manage',
                      subject: 'Auth',
                    },
                  ]
                  this.isNavMenuHidden = false
                } else if (userData.level.category === "AP/P") {
                  userData.ability = [
                    {
                      action: 'manage',
                      subject: 'PartnerAccess',
                    },
                    {
                      action: 'manage',
                      subject: 'UserAccess',
                    },
                    {
                      action: 'manage',
                      subject: 'InactiveUserAccess',
                    },
                    {
                      action: 'manage',
                      subject: 'Auth',
                    },
                  ]
                  this.isNavMenuHidden = true
                } else {
                  userData.ability = [
                    {
                      action: 'manage',
                      subject: 'UserAccess',
                    },
                    {
                      action: 'manage',
                      subject: 'InactiveUserAccess',
                    },
                    {
                      action: 'manage',
                      subject: 'Auth',
                    },
                  ]
                  this.isNavMenuHidden = true
                }
              } else {
                userData.ability = [
                  {
                    action: 'manage',
                    subject: 'InactiveUserAccess',
                  },
                  {
                    action: 'manage',
                    subject: 'Auth',
                  },
                ]
              }

              useJwt.setToken(response.data.token)
              // useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router.replace(getHomeRouteForLoggedInUser(userData))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${userData.fullName || userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `You have successfully logged in as ${userData.role}.`,
                    },
                  })
                })
            })
            .catch(err => {

              const errorMessage = (err.response && err.response.data && err.response.data.error) || err || 'Incorrect Email or Password.'
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  text: errorMessage,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
              this.$refs.loginForm.setErrors(err?.response?.data?.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
